import React from "react"



const SSS = () => (


    <div className="hiring-tabs">
        <input type="checkbox" name="dropdown" id="tab1" />
        <input type="checkbox" name="dropdown" id="tab2" />
        <input type="checkbox" name="dropdown" id="tab3" />
        <input type="checkbox" name="dropdown" id="tab4" />
        <input type="checkbox" name="dropdown" id="tab5" />

        <div className="tab" id="tab1sec">
            <label htmlFor="tab1"> </label>
            <h3>İçerik pazarlamanın amacı nedir?</h3>
            <p>En temel şekilde, içerikler aracılığı ile hedef kitleyi etkilemek ve sürdürülebilir bir iletişim bağı kurabilmektir diyebiliriz.
             Bu sayede hedef kitlenin markanın istediği davranışı gerçekleştirme (dönüşüm) olasılığının artması sağlanacaktır.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab2sec">
            <label htmlFor="tab2"> </label>
            <h3>Bir defaya mahsus içerik pazarlama hizmeti almak yeter mi?</h3>
            <p>İçerik pazarlama, düzenli olarak sürdürüldüğü zaman hedefleri gerçekleştirmeye yardımcı olmaktadır. Bu nedenle tutarlı bir şekilde
             içerik pazarlama çalışması yapmak, bir defaya mahsus hizmet almaktan her zaman daha faydalı olacaktır.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab3sec">
            <label htmlFor="tab3"> </label>
            <h3>İçerik pazarlama hangi sektördeki markalara fayda sağlar?</h3>
            <p>Sektörden bağımsız olarak hedef kitlesine erişmek ve onlara bir değer sunmak isteyen her marka içerik pazarlama ile 
                fayda sağlayabilir.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab4sec">
            <label htmlFor="tab4"> </label>
            <h3>İçerik pazarlama ile SEO farklı mıdır?</h3>
            <p>Evet. SEO (Arama Motoru Optimizasyonu), web sitelerinin arama motoru sonuçlarında daha üst sıralarda yer alması için yapılan 
            iyileştirme çalışmalarının tümünü temsil eder. İçerik pazarlama ise hedef kitleye erişmek için başvurulan yöntemlerden biridir.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
    
    </div>
)

export default SSS
