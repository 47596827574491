import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Company from "../components/content-company"
import rightArrow from "../assets/img/right-arrow.png";

import icerik from "../assets/img/icerik-pazarlama.png"
import blog from "../assets/img/pazarlama-icerikleri.png"
import pazarlama from "../assets/img/blog-icerik.png"
import yapisal from "../assets/img/yapisal-icerikler.png"
import work1 from "../assets/img/work-preformance.png"
import Teams from "../components/teams/seo-team"
import SSS from "../components/sss/content-sss"
import Services from "../assets/img/services-head.png"

import ikon1 from "../assets/img/ip-ikon1.png"
import ikon2 from "../assets/img/ip-ikon2.png"
import ikon3 from "../assets/img/ip-ikon3.png"
import ikon4 from "../assets/img/ip-ikon4.png"
import ikon5 from "../assets/img/ip-ikon5.png"
import ikon6 from "../assets/img/ip-ikon6.png"
import ikon7 from "../assets/img/ip-ikon7.png"
import ikon8 from "../assets/img/ip-ikon8.png"
import ikon9 from "../assets/img/ip-ikon9.png"
import ikon10 from "../assets/img/ip-ikon10.png"



import checkModal from "../assets/img/check-modal.png"

class SocialMedia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="İçerik pazarlama" description="Her gun değişen sosyal medya trendlerini izliyor, kitleleri etkileyecek fark yaratan, alışılmışın dışında içerikler tasarlıyoruz." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1>İçerik <br /> Pazarlama</h1>
                            <span className="red-head">HİZMETLER</span>
                            <p>Hizmet verdiğimiz markaların hedef kitlelerinin dikkatini çekerken <a href="https://flatart.com.tr/" target="_blank">dijital pazarlama </a>stratejisi ile de örtüşecek orijinal içerikler üretiyoruz. </p>
                            <input type="text" className="pink-input" placeholder="E-posta adresinizi girin..." value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="social-button" onClick={(e) => this.showModal()}>Size özel teklif alın <img alt="alt text" src={rightArrow}></img></button>
                        </div>
                        <div className="col-md-6" style={{ marginTop: -18.5, paddingLeft: 30 }}><img alt="alt text" src={Services}  /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Websitenizi girin." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form>
                                    <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus id="site" required  />
                                    <input type="text" placeholder="Adınızı girin." />
                                    <input type="email" placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container brands_content py60">
                    
                    <div className="studies">
                        <h2 style={{ marginBottom: 50, marginTop: -50 }}>İlgi çekici, özgün ve değişen trendlere uygun içerikler üreterek <br /> markalarımızın mevcut 
                       ve potansiyel müşterileri  <br />ile iletişim kurmasına önayak oluyoruz.</h2>
                    </div>
                    <h2>Referanslarımız</h2>
                     <p>Öne çıkan referans markalar.</p>
                    <div className="companys__social">
                        <Company />
                    </div>
                </div>

                <div className="container brands_content py60" style={{ marginTop: 20 }}>
                    <h2>İçerik Pazarlama Hizmet Başlıkları</h2>
                </div>

                <div className="acquire-bottom social-media-contents container">
                    <div className="acquire-bottom__left">
                        <div className="acquire-bottom__left-img"><img alt="alt text" src={pazarlama} style={{ width: '100%' }} /></div>
                        <div className="acquire-bottom__left-content">
                        <h2>Pazarlama<br /> İçerikleri</h2>
                            <p>
                            Üreteceğimiz benzersiz ve değer yaratan website içerikleri ile markanızı hedef kitlenizin zihninde konumlandırın.</p>
                        </div>
                    </div>

                    <div className="acquire-bottom__right" >
                        <div className="acquire-bottom__left-content" id="icerik-pazarlama">
                        <h2>Yapısal İçerikler</h2>
                        <p>Ürün/hizmet ve kategori sayfalarınız için üreteceğimiz zamansız içerikler ile mevcut ve potansiyel müşterilerinize ulaşın.</p>
                        </div>
                        <div className="acquire-bottom__right-img"><img alt="alt text" src={yapisal} style={{ width: '100%' }} /></div>
                    </div>
                    <div className="acquire-bottom__left">
                        <div className="acquire-bottom__left-img"><img alt="alt text" src={blog} style={{ width: '100%' }}/></div>
                        <div className="acquire-bottom__left-content">
                            <h2>SEO Uyumlu <br />Blog İçerikleri</h2>
                            <p>Hedef kitlenizin web eğilimlerine uygun olarak hazırlayacağımız blog içerikleri ile organik trafiğinizi artırın.</p>

                        </div>
                    </div>
                </div>

                <div className="onboarding container py60" >
                    <div className="col-md-4">
                        <img alt="alt text" src={work1} />
                        <h2>İçerik Pazarlama <br />sürecimiz.</h2>
                        <p>Markalarımız için içerik pazarlama sürecimizi nasıl sürdürdüğümüze göz atın.</p>
                    </div>
                    <div className="col-md-8">
                        <div className="hiring-tabs">
                            <input type="checkbox" name="onboarding" id="tab6" />
                            <input type="checkbox" name="onboarding" id="tab7" />
                            <input type="checkbox" name="onboarding" id="tab8" />
                            <input type="checkbox" name="onboarding" id="tab9" />
                            <input type="checkbox" name="onboarding" id="tab10" />
                            <input type="checkbox" name="onboarding" id="tab11" />

                            <div className="tab" id="tab6sec">
                                <label htmlFor="tab6"> </label>
                                <h3> <span>1</span> Hedefleri Tanımlama </h3>
                                <p>- Hangi alanda içerik pazarlama ihtiyacı olduğu ve bu çalışma ile hangi pazarlama kanallarına katkı sunulmasının amaçlandığı tespit edilir.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab7sec">
                                <label htmlFor="tab7"> </label>
                                <h3> <span>2</span> Araştırma ve Analiz</h3>
                                <p>- Erişilmek istenen kitlenin profili çıkarılır, kullanıcı eğilimleri incelenir, rakiplerin çalışmaları izlenir.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab8sec">
                                <label htmlFor="tab8"> </label>
                                <h3> <span>3</span> İçerik Planı Oluşturma</h3>
                                <p>- İçeriğin yer alacağı mecraya uygun formatlar belirlenir ve içerik üretimine başlanır.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab9sec">
                                <label htmlFor="tab9"> </label>
                                <h3> <span>4</span> İçeriği Kullanıcı Erişimine Açma</h3>
                                <p>- İçerikler yayınlanır ve içeriklerin doğru kitle ile buluşması için on-page ve off-page çalışmalar yürütülür.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab10sec">
                                <label htmlFor="tab10"> </label>
                                <h3> <span>5</span> Performans Ölçümü ve Değerlendirme</h3>
                                <p>- Kullanıcı etkileşimi ve içeriklerin KPI’lara olan etkileri düzenli olarak ölçümlenir ve raporlanır.</p>
                                <div className="plus"></div>
                            </div>
                            
                        </div>
                    </div>
                </div>

             
 
                <div className="container brands_content py60" style={{marginBottom:20,  marginTop: 10}}>
                    <h2 style={{marginBottom:50}}>İçerik Pazarlama Hizmetinin <br />Markalara Sağlayacağı Başlıca 10 Katkı</h2>
                         <div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon1} />
                                <div>
                                <p style={{fontSize:18}}> 1. Marka Farkındalığının Sağlanması</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon2} />
                                <div>
                                <p style={{fontSize:18}}> 2. İtibarın Artırılması</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon3} />
                                <div>
                                <p style={{fontSize:18}}> 3. Hedef Kitle ile Güven Bağı Oluşturulması</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon4} />
                                <div>
                                <p style={{fontSize:18}}> 4. Geniş Kitlelere Erişme İmkanı</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon5} />
                                <div>
                                <p style={{fontSize:18}}> 5. Kaliteli Trafik Edinimi</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon6} />
                                <div>
                                <p style={{fontSize:18}}> 6. Dönüşüm Potansiyelinin Artırılması</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon7} />
                                <div>
                                <p style={{fontSize:18}}> 7. Arama Motoru Görünürlüğünün Artırılması</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon8} />
                                <div>
                                <p style={{fontSize:18}}> 8. Sosyal Medya Etkileşiminin Artırılması</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img"alt="alt text" src={ikon9} />
                                <div>
                                    <p style={{fontSize:18}}> 9. Pazarlama Maliyetlerinin Düşürülmesi</p>
                                </div>
                            </div>
                            <div className="col-md-4 manage-google_item">
                                <img class="icerikp-img" alt="alt text" src={ikon10} />
                                <div>
                                <p style={{fontSize:18}}> 10. Müşteri Deneyiminin Geliştirilmesi</p>
                                </div>
                            </div>
                        </div>
                </div>
              
                {/*<div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Size yardımcı olabilecek <br />Google Ads uzmanlarımız. </h2>
                    <Teams />
                </div>*/}
                <div className="hiring" style={{ marginTop: -20 }}>
                    <h2>Sıkça Sorulan Sorular</h2>
                    <SSS />
                </div>
            </Layout>
        )
    }
}
export default SocialMedia
